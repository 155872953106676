
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  import { Aggregate, Cursor, Filter, ResourceType } from '@/entities/public/Resource/interfaces'
  import { loadResources } from './resources'
  import { Models } from '@/graphql/forms'
  import { TableCellHeader } from '@/components/dataTables/cell'
  import { Resource } from '@/entities/public'
  import { ResourceData } from '@/store/resources/datatables/state'
  import { Debounce } from '@/utils/decorators'
  import { Period } from '@/store/resources/period'
  import { View } from '@/entities/public/Resource/metadata'

  let _getHeaders: (slug: string) => Promise<Array<TableCellHeader>>
@Component({
  components: {
    BaseTable: () => import('@/components/dataTables/BaseTable.vue'),
    DataPanel: () => import('@/components/dashboards/MiniDataPanel.vue'),
  },
  methods: {
    ...mapActions('resources/datatables', ['fetchData']),
    ...mapMutations('resources/datatables', ['setData']),
    ...mapMutations('persons', ['setId', 'setEmbedPerson']),
    ...mapMutations('resources/form', ['setBackup', 'setStaticForm']),
  },
  computed: {
    ...mapGetters('resources', ['cursor', 'active', 'filter', 'period']),
    ...mapGetters('resources/datatables', ['data']),
  },
})
  export default class ResourceView extends Vue {
  @Prop({ type: String, required: true }) role!: string

  loading = false
  slug = ''
  backup = []
  change: boolean = false
  model = null
  showPeriod = true

  private cursor!: Cursor
  private active!: Resource
  private filter!: Filter
  private period!: Period
  private data!: ResourceData
  private timer: any;

  selectedFilter = null
  isCustomSearch: boolean = false
  headers: Array<TableCellHeader> = []

  fetchData!: (payload: { resource: Resource, period: Period, filter?: Filter, cursor?: Cursor }) => Promise<void>
  setData!: (payload: ResourceData) => void
  setId!: (id: number) => void
  setEmbedPerson!: (payload) => void
  private setBackup!: (payload) => void
  setStaticForm!: (payload) => void

  mounted () {
    this.setId(null)
    this.setEmbedPerson(null)
    this.setBackup(undefined)
    this.setStaticForm(false)
    this.startTimer()
  }

  private updateDt (): void {
    this.change = !this.change
  }

  private startTimer (): void {
    if (!this.timer) {
      this.timer = setInterval(this.updateDt, (60000) * 5)
    }
  }

  beforeDestroy (): void {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  get records (): Array<ResourceType> {
    const { active, metadata, data, loading } = this

    if (!this.isRecordActiveInstance(data.records, metadata)) return this.backup
    if (!active || !data || loading) return []
    this.backup = data.records
    return data.records
  }

  isRecordActiveInstance (records, metadata) {
    if (!records.length) return true

    if (!metadata?.query?.model) return false
    const model = Models[metadata?.query?.model]

    return records.some(record => record instanceof model)
  }

  get metadata (): View {
    const { active } = this
    return active?.metadata as View
  }

  get aggregates (): Array<Aggregate> {
    const { metadata } = this
    if (!metadata) return []

    const { aggregates } = metadata
    return aggregates
  }

  get changeData () {
    const { active, filter, period, selectedFilter } = this
    if (!active?.id || !filter?.name || !period?.where) return {}
    return { resource: active, filter, period, selectedFilter }
  }

  getHeaders (slug: string) {
    if (!_getHeaders) _getHeaders = loadResources(this.$router, this.$store)
    return _getHeaders(slug)
  }

  @Watch('changeData', { deep: true, immediate: true })
  @Debounce(500)
  async setHeaders ({ resource, filter, period, selectedFilter }): Promise<void> {
    const { cursor } = this
    if (!resource?.id) return

    let customPeriod = period
    this.headers = await this.getHeaders(resource.slug)
    this.slug = resource.slug
    const isCustomSearch = Boolean(resource?.metadata?.filterFields?.length)
    this.isCustomSearch = isCustomSearch
    this.loading = true

    if (resource?.slug?.includes('stock') || resource?.slug?.includes('sysop_employee')) {
      customPeriod = {}
      this.showPeriod = false
    }

    const newFilter = {
      name: filter?.name,
      title: filter?.title,
      where: selectedFilter && isCustomSearch ? {
        _and: [
          filter?.where,
          ...(Array.isArray(selectedFilter) ? selectedFilter : [selectedFilter]),
        ].filter(Boolean),
      } : filter?.where,
    }

    const newCursor = {
      size: cursor.size,
      page: selectedFilter && isCustomSearch ? 1 : cursor.page,
    }

    await this.fetchData({ resource, period: customPeriod, filter: newFilter, cursor: newCursor })
    this.loading = false
    this.model = resource?.metadata?.model
  }

  onSearch ({ filter, condition }: { filter: any[], condition: boolean }) {
    if (condition) {
      this.selectedFilter = {
        _or: this.handleFilter(filter),
      }
    } else {
      // Si la condición es false, simplemente retornamos los filtros
      this.selectedFilter = this.handleFilter(filter)
    }
  }

  handleFilter (filter) {
    console.log(filter)
    return filter.flatMap(selectedFilters => {
      // Verificamos si hay más de una query en el array
      if (selectedFilters.filter.length > 1) {
        // Si hay más de una, agrupamos en un _or
        return {
          _or: selectedFilters.filter.map(selectedFilter => selectedFilter),
        }
      } else {
        // Si solo hay una, retornamos directamente
        return selectedFilters.filter.map(selectedFilter => selectedFilter)
      }
    })
  }
  }
